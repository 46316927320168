<template>
  <div>
    <v-progress-linear indeterminate v-show="loading"></v-progress-linear>
    <v-alert type="error" v-if="error">{{ error }}</v-alert>

    <v-row v-if="data">
      <v-col cols="12" md="4">
        <rental-revenue :data="data" />
      </v-col>
      <v-col cols="12" md="8">
        <properties :data="data" />
      </v-col>

      <v-col cols="12" md="8">
        <revenue-evolution :data="data" />
      </v-col>

      <v-col cols="12" md="4">
        <v-row class="match-height">
          <v-col cols="12">
            <stats-defaulter :data="data" />
          </v-col>

          <v-col cols="6">
            <stats-properties :data="data" />
          </v-col>

          <v-col cols="6">
            <stats-customers :data="data" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import REPORT from '@/services/report'

import RentalRevenue from './RentalRevenue.vue'
import Properties from './Properties.vue'
import RevenueEvolution from './RevenueEvolution.vue'
import StatsDefaulter from './StatsDefaulter.vue'
import StatsProperties from './StatsProperties.vue'
import StatsCustomers from './StatsCustomers.vue'

export default {
  data: () => ({
    data: null,
    loading: false,
    error: null,
  }),
  methods: {
    async getReport() {
      try {
        this.loading = true
        const response = await REPORT.home()
        this.data = response
      } catch (error) {
        this.error = error.message || "Ocorreu um erro ao carregar os dados."
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.getReport()
  },
  components: {
    RentalRevenue,
    Properties,
    RevenueEvolution,
    StatsDefaulter,
    StatsProperties,
    StatsCustomers,
  },
}
</script>
