<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0">
      <v-col cols="8" md="9">
        <v-card-title class="text-no-wrap pt-1 ps-2">Total de Receitas</v-card-title>
        <v-card-subtitle class="text-no-wrap ps-2"> Receitas com Locação </v-card-subtitle>
        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
          <div>
            <h1 class="text-4xl font-weight-semibold primary--text mb-0">R$ {{ total | beautifulNumber }}</h1>
            <small class="mb-0 success--text">{{ total | currency }}</small>
          </div>
        </v-card-text>
      </v-col>

      <v-col cols="4" md="3">
        <v-img
          contain
          height="auto"
          width="145"
          :src="require(`@/assets/images/common/triangle-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
          class="greeting-card-bg"
        ></v-img>
        <!-- <v-img
          contain
          height="auto"
          max-width="145"
          class="greeting-card-cash"
          :src="require(`@/assets/images/common/cash-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
        ></v-img> -->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'RentalRevenue',
  computed: {
    total() {
      return this.data.payments
        .filter(payment => payment.status === 'Paid')
        .reduce((acc, payment) => {
          const value = parseFloat(payment.value)
          const penalty = parseFloat(payment.penalty)
          const fees = parseFloat(payment.fees)
          const discount = parseFloat(payment.discount)

          return acc + value + penalty + fees - discount
        }, 0)
    },
  },

  filters: {
    beautifulNumber(value) {
      value = parseFloat(value)
      const opts = [
        ['K', 1000],
        ['M', 1000000],
        ['B', 1000000000],
        ['T', 1000000000000],
      ]
      let i = opts.length - 1
      while (i >= 0) {
        if (value >= opts[i][1]) {
          return (value / opts[i][1]).toFixed(1) + opts[i][0]
        }
        i--
      }
      return value.toFixed(2).replace('.', ',')
    },
    currency(value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
  },
}
</script>
