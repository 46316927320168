<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="text-no-wrap">Imóveis</span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-subtitle class="mb-4">
      <span class="text-no-wrap">Status</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col v-for="d in items" :key="d.title" cols="6" md="3" class="d-flex align-center">
          <v-avatar size="50" :color="d.color" rounded>
            <v-icon dark color="white" size="30">
              {{ d.icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ d.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ d.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    reduceImmobiles() {
      return this.data.immobiles.reduce(
        (acc, immobile) => {
          if (!immobile.rental) {
            acc.available++
          } else if (immobile.rental.status == 'active') acc.rented++
          else if (immobile.rental.status == 'pending') acc.reserved++

          return acc
        },
        {
          available: 0,
          rented: 0,
          reserved: 0,
          total: this.data.immobiles.length,
        },
      )
    },
    items() {
      const d = this.reduceImmobiles
      const statisticsData = [
        {
          title: 'Disponíveis',
          total: d.available,
          icon: 'mdi-home-city-outline',
          color: 'primary',
        },
        {
          title: 'Locados',
          total: d.rented,
          icon: 'mdi-home-city-outline',
          color: 'success',
        },
        {
          title: 'Propostas',
          total: d.reserved,
          icon: 'mdi-file-document-edit-outline',
          color: 'warning',
        },
        {
          title: 'Vacância',
          total: ((d.available / d.total) * 100).toFixed(0) + '%',
          icon: 'mdi-percent-outline',
          color: 'info',
        },
      ]

      return statisticsData
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
