<template>
  <v-card>
    <v-card-title class="align-start pb-0">
      <v-avatar color="error" size="38">
        <v-icon size="24" color="white" class="rounded-0">mdi-currency-usd</v-icon>
      </v-avatar>
      <v-spacer></v-spacer>

      <v-btn :to="{ name: 'contracts' }" small icon class="me-n3 mt-n1">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary mt-3">
      <p class="font-weight-semibold text-sm mb-1">Inadimplência</p>

      <v-row>
        <v-col cols="">
          <div class="d-flex align-end flex-wrap">
            <span class="font-weight-semibold text-2xl me-1 mb-2">{{ reducedPayments.count }}</span>
          </div>
          <p class="text-xs text--secondary mb-0">Qtde</p>
        </v-col>
        <v-col cols="auto">
          <div class="d-flex align-end flex-wrap">
            <span class="font-weight-semibold text-2xl me-1 mb-2"
              >R$ {{ reducedPayments.total | beautifulNumber }}</span
            >
          </div>
          <p class="text-xs text--secondary mb-0">Valor Total</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  name: 'StatsDefaulter',

  computed: {
    reducedPayments() {
      return this.data?.payments
        ?.filter(payment => {
          return moment(payment.validity).isBefore(moment()) && payment.status === 'Pending'
        })
        .reduce(
          (acc, payment) => {
            acc.total + payment.value
            acc.count + 1
            return acc
          },
          {
            total: 0,
            count: 0,
          },
        )
    },
  },
  filters: {
    beautifulNumber(value) {
      value = parseFloat(value)
      const opts = [
        ['K', 1000],
        ['M', 1000000],
        ['B', 1000000000],
        ['T', 1000000000000],
      ]
      let i = opts.length - 1
      while (i >= 0) {
        if (value >= opts[i][1]) {
          return (value / opts[i][1]).toFixed(1) + opts[i][0]
        }
        i--
      }
      return value.toFixed(2).replace('.', ',')
    },
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss" scoped></style>
