<template>
  <v-card class="card-sparkline">
    <v-card-title class="align-start">
      <span>Evolução Anual</span>
    </v-card-title>

    <v-card-text>
      <v-card-text class="px-0 pb-0">
        <v-sheet color="transparent">
          <v-sparkline
            :value="valuesSum"
            label-size="5"
            color="primary"
            height="112px"
            padding="14"
            stroke-linecap="round"
            line-width="3"
            auto-draw
            smooth
          >
            <template v-slot:label="item"> {{ values[item.index].month }} {{ item.value | beautifulNumber }} </template>
          </v-sparkline>
        </v-sheet>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
moment.locale('pt-br')

export default {
  data: () => ({}),

  computed: {
    values() {
      const interval = [moment().subtract(1, 'year'), moment()]
      var populated = [],
        index = {}
      for (let i = 11; i >= 0; i--) {
        index[moment().subtract(i, 'month').format('YYYY-MM')] = 11 - i
        populated[11 - i] = {
          month: moment().subtract(i, 'months').format('MMM'),
          sum: 0,
        }
      }

      return this.data.payments.reduce((acc, payment) => {
        const date = moment(payment.payDate)
        if (date.isBetween(interval[0], interval[1])) {
          const indx = index[date.format('YYYY-MM')]
          if (indx) {
            const value = parseFloat(payment.value),
              penalty = parseFloat(payment.penalty),
              fees = parseFloat(payment.fees),
              discount = parseFloat(payment.discount),
              amount = value + penalty + fees - discount
            acc[indx].sum += amount
          }
        }

        return acc
      }, populated)
    },
    valuesSum() {
      return this.values.map(value => value.sum)
    },
  },
  filters: {
    beautifulNumber(value) {
      value = parseFloat(value)
      const opts = [
        ['K', 1000],
        ['M', 1000000],
        ['B', 1000000000],
        ['T', 1000000000000],
      ]
      let i = opts.length - 1
      while (i >= 0) {
        if (value >= opts[i][1]) {
          return (value / opts[i][1]).toFixed(1) + opts[i][0]
        }
        i--
      }
      return value.toFixed(2).replace('.', ',')
    },
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped></style>
