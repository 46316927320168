<template>
  <v-card>
    <v-card-title class="align-start pb-0">
      <v-avatar color="primarylight" size="38">
        <v-icon size="24" color="white" class="rounded-0">mdi-home-city-outline</v-icon>
      </v-avatar>
      <v-spacer></v-spacer>

      <v-btn :to="{ name: 'immobile' }" small icon class="me-n3 mt-n1">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary mt-3">
      <p class="font-weight-semibold text-sm mb-1">Imóveis</p>

      <div class="d-flex align-end flex-wrap">
        <span class="font-weight-semibold text-2xl me-1 mb-2">{{ data.immobiles.length }} </span>
      </div>
      <p class="text-xs text--secondary mb-0">Cadastros</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
</style>